.zoom {
  transition: transform .2s; /* Animation */
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.project_description{
  padding: 25px;
  margin-top: -3px;
  border-radius: 0 0 15px 15px;
  background-color: #4e2852;
  height: 67%;
}
.project_heading{
  font-size: 26px;
  font-weight: bold;
}
.contact_allign{
  display: flex;
  flex-direction: column;
}

.contact_section{
  padding-top: 40px;
  display: flex;
  justify-content: space-around;
  width: 70%;
  
}
/* Keyframes */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}
.contact_section > div{
  width: 8%;
  animation: wiggle 2s linear infinite;
}
.contact_section img{
  width: 100%;
}

@media screen and (max-width: 900px) {
  .contact_section{
  width: 80%;
  
}
}
